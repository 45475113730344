import * as React from "react";
import Search from "../Search";

const IndexHero = () => {
  return (
    <div className="hero min-w-full lg:px-2 md:py-24 my-10 md:my-0">
      {/*md:h-screen/3 lg:h-screen/2*/}
      <div className="hero-content text-center flex flex-col">
        <div className="hero-title">
          <h1 className="text-5xl font-bold">
            Find books 📚
            <br />
            you need to grow your startup 🚀
          </h1>
        </div>
        <div className="py-8 sm:pt-7 w-full flex flex-col justify-center gap-y-9">
          <div className="form-control w-full flex flex-col sm:flex-row justify-center">
            <div className="max-w-fit sm:pr-4 self-center">
              <span className="label-text text-xl font-semibold">
                I want to learn about
              </span>
            </div>
            <div className="flex-1">
              <Search />
            </div>
          </div>
          <div className="find-button">
            <button className="btn btn-primary btn-wide text-xl text-primary-content">
              Find my book
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexHero;
