import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { slugifyWithRegex } from "../../utilities/utilities";

const TopicsCard = ({ data }) => {
  return (
    <Link to={`/topics/${slugifyWithRegex(data.Topics)}`}>
      <div className="card card-compact md:card-normal h-full bg-base-200 shadow-xl drop-shadow-lg hover:scale-105">
        <figure>
          {data.topic_covers &&
          data.topic_covers.localFiles[0].childImageSharp ? (
            <GatsbyImage
              image={
                data.topic_covers.localFiles[0].childImageSharp.gatsbyImageData
              }
              objectFit="contain"
              alt={data.Topics}
              formats={["auto", "webp", "avif"]}
            />
          ) : (
            <StaticImage
              alt="book-image"
              src="../../images/topics_card_placeholder.jpg"
              formats={["auto", "webp", "avif"]}
            />
          )}
        </figure>
        <div className="card-body flex flex-row items-center">
          <div className="left-topics flex-1">
            <h2 className="card-title text-2xl">{data.Topics}</h2>
          </div>
          <div className="card-actions justify-end">
            <button
              className="btn btn-circle bg-secondary"
              aria-label={`Go to ${data.Topics}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                stroke="currentColor"
              >
                <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TopicsCard;
