import * as React from "react";

import Layout from "../components/Layout.js";
import IndexHero from "../components/indexComponents/IndexHero.js";
import IndexTopicGrid from "../components/indexComponents/IndexTopics.js";

const IndexPage = () => {
  return (
    <Layout headerRightElement="twitterButton">
      <IndexHero />
      <IndexTopicGrid />
    </Layout>
  );
};

export default IndexPage;
