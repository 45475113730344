import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import TopicsCard from "../topicComponents/TopicCards";

const IndexTopicGrid = () => {
  const { poplularTopics, topicCounts } = useStaticQuery(graphql`
    query TopicsQuery {
      poplularTopics: allAirtable(
        filter: {
          table: { eq: "book_topics" }
          data: { popular: { eq: true } }
        }
      ) {
        edges {
          node {
            id
            data {
              Topics
              image_url
              topic_covers {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        totalCount
      }
      topicCounts: allAirtable(filter: { table: { eq: "book_topics" } }) {
        totalCount
      }
    }
  `);

  return (
    <div className="index-topics-grid">
      <div className="index-topics-grid-header">
        <h2 className="text-3xl font-bold my-3">Topics</h2>
        <p className="text-lg">
          If you’re starting out these are some good topics to start
        </p>
      </div>
      <div className="index-topics-grid-cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 items-stretch mt-6">
        {poplularTopics.edges.map(topic => (
          <div key={topic.node.id}>
            <TopicsCard data={topic.node.data} />
          </div>
        ))}
        <div className="index-topics-grid-card"></div>
      </div>
      <div className="explore-more-section">
        <Link to="/topics">
          <p className="text-center neutral-content font-medium">{`Explore ${topicCounts.totalCount} topics`}</p>
        </Link>
      </div>
    </div>
  );
};

export default IndexTopicGrid;
